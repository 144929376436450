import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer"><p>&#169; 2021 all copyright : BooksWorms.com</p>
    <p>Email: booksworms@gmail.com</p>
    <p>Tel: 00962-798-777-222</p>
    </div>
  );
};

export default Footer;
